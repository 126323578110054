import { twMerge } from 'tailwind-merge';

export type ButtonVariants =
  | 'white-outline'
  | 'purple'
  | 'green'
  | 'yellow'
  | 'red'
  | 'bold-text'
  | 'none';

const sharedButtonClassNames =
  'rounded-md py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors ease-out';

export const buttonClassNames: Record<ButtonVariants, string> = {
  'white-outline': `border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 ${sharedButtonClassNames}`,
  purple: `bg-indigo-600 text-white hover:bg-indigo-700 ${sharedButtonClassNames}`,
  green: `bg-green-500 text-white font-bold hover:bg-green-600 ${sharedButtonClassNames}`,
  yellow: `border border-gray-300 bg-amber-300 text-gray-800 hover:bg-yellow-400 ${sharedButtonClassNames}`,
  'bold-text': 'font-semibold',
  red: `bg-red-700 text-white hover:bg-red-800 ${sharedButtonClassNames} focus:ring-red-300 `,
  none: '',
};

// Merge classnames, discarding false values
export function classNames(...classes: (string | false | undefined | null)[]) {
  return twMerge(classes.filter(Boolean).join(' '));
}

// Centered column of page content below breadcrumbs
export const pageContentClassName =
  'mx-auto flex w-full max-w-5xl grow flex-col py-4 sm:px-6 lg:px-8';
